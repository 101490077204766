import React, { useState } from 'react';

function Modal() {
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <div>
            <button className='modalBtn' onClick={openModal}>Objectif</button>

            {isOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span onClick={closeModal} className="close-button">
                            &times;
                        </span>
                        <h2>jeu de Giguy</h2>
                        <p>Description du jeu :</p>
                        <p>
                            Elaboré pour répondre aux besoins éducatifs des enfants atteints d'autisme. Il a été soigneusement conçu pour aider ces enfants à développer leurs compétences en lecture de manière à la fois ludique et interactive.
                        </p>
                        <p>
                            Objectif :
                        </p>
                        <p>
                            L'objectif principal de ce serious game est de créer une expérience d'apprentissage de la lecture positive et attrayante pour les enfants autistes. Le jeu s'appuie sur des méthodes pédagogiques adaptées à leurs besoins particuliers, visant à rendre l'apprentissage de la lecture plus accessible et agréable.</p>
                        <p>Règles du jeu :</p>
                        <p>Case Départ - Arrivée - Rejoue : Si tu tombes sur cette case, relance le dé pour rejouer.</p>
                        <p>Case Gourou Giguy : Si tu tombes sur Giguy, une carte aléatoire sera découverte et tu devras suivre les consignes qui lui sont attribuées.</p>
                        <p>Case Farfadet breton : Si tu tombes sur un Farfadet Breton, choisis une catégorie de mots à lire ensuite.</p>
                        <p>Case Astuce / Œil : Si tu tombes sur la case œil, prononce le mot qu'il représente, puis le son associé.</p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Modal;
