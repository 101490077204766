import React from 'react';

class SelectCategorie extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: null
        };
    }

    handleCategorySelect = (category) => {
        this.setState({ selectedCategory: category });
    }

    render() {
        const { selectedCategory } = this.state;

        return (
            <>
                <div className='categorie'>
                    <h2 className='CategorieSelect'>Choisir une catégorie :</h2>
                    <div className='categorieContent'>
                        <button
                            onClick={() => this.props.onSelect('vert')}
                        >
                            <img className='categorieImg' src="./assets/images/viewCategory/categorie1.png" alt="Catégorie 1" />
                        </button>

                        <button
                            onClick={() => this.props.onSelect('bleu')}
                        >
                            <img className='categorieImg' src="./assets/images/viewCategory/categorie2.png" alt="Catégorie 2" />
                        </button>
                    </div>

                </div>
            </>
        );
    }
}

export default SelectCategorie;
