import React, { useState } from 'react';

const De = ({ avancerPion }) => {
    const [resultatDe, setResultatDe] = useState(null);
    const [rolling, setRolling] = useState(false);
    const [showImage, setShowImage] = useState(true); // Ajout de l'état pour l'image

    const lancerDe = () => {
        if (rolling) return; // Empêcher de lancer le dé pendant qu'il roule

        setRolling(true);
        setResultatDe(null); // Réinitialiser le résultat précédent

        setTimeout(() => {
            const resultat = Math.ceil(Math.random() * 6);
            setResultatDe(resultat);
            avancerPion(resultat);
            setRolling(false); // Fin de l'animation
            setShowImage(false); // Cacher l'image lorsque le dé est cliqué
        }, 500); // Correspond au temps de l'animation
    };

    const numberToWord = (number) => {
        const words = ["one", "two", "three", "four", "five", "six"];
        return words[number - 1];
    };

    return (
        <div className='deContainer'>
            <div className={`de ${resultatDe ? `${numberToWord(resultatDe)}` : ''} ${rolling ? 'rolling' : ''}`} onClick={lancerDe}>
                {showImage && <img src="./assets/images/pion/play.png" alt="Image du dé" />} {/* Ajout de l'image */}
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
            </div>
        </div>
    );
}

export default De;
