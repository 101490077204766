import React from 'react';

const Carte = ({ image, categorie }) => {
    return (
        <div className={`carte categorie-${categorie}`}>
            <img className='cardGame' src={`./assets/images/${image}`} alt={`Carte ${categorie}`} />
        </div>
    );
}

export default Carte;
