import React from 'react';
import Case from '../Case';
import De from '../De';
import Pion from '../Pion';
import Carte from '../Carte';
import Modal from '../../Modal';
import SelectCategorie from '../../SelectCategorie'; // Assurez-vous que le chemin d'importation est correct


class Board extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            positionPion: 1, // Commencer à la position 0
            dernierLancer: null, // Pour stocker le résultat du dernier lancer
            afficherOptionsCategorie: false,
            cartes: {
                1: Array.from({ length: 1 }, (_, i) => ({ id: `1-${i + 1}`, image: `categorie1/image${i + 1}.jpeg` })),
                2: Array.from({ length: 2 }, (_, i) => ({ id: `1-${i + 1}`, image: `categorie2/image${i + 1}.png` })),
                // 3: Array.from({ length: 5 }, (_, i) => ({ id: `1-${i + 1}`, image: `categorie3/image${i + 1}.png` })),
                4: Array.from({ length: 3 }, (_, i) => ({ id: `1-${i + 1}`, image: `categorie4/image${i + 1}.png` })),
                // 5: Array.from({ length: 5 }, (_, i) => ({ id: `1-${i + 1}`, image: `categorie3/image${i + 1}.png` })),
                6: Array.from({ length: 3 }, (_, i) => ({ id: `1-${i + 1}`, image: `categorie4/image${i + 1}.png` })),
                3: {
                    vert: Array.from({ length: 4 }, (_, i) => ({ id: `3-vert-${i + 1}`, image: `categorie3/vert/image${i + 1}.png` })),
                    bleu: Array.from({ length: 4 }, (_, i) => ({ id: `3-bleu-${i + 1}`, image: `categorie3/bleu/image${i + 1}.png` }))
                },
                5: {
                    vert: Array.from({ length: 4 }, (_, i) => ({ id: `5-vert-${i + 1}`, image: `categorie3/vert/image${i + 1}.png` })),
                    bleu: Array.from({ length: 4 }, (_, i) => ({ id: `5-bleu-${i + 1}`, image: `categorie3/bleu/image${i + 1}.png` }))
                },
            },
            carteTiree: null,
            categorieVisible: null, // Aucune catégorie n'est visible au début
            categorieSelectionnee: false, // Ajout de cet état
        };
    }
    tirerCarte = (categorie, callback) => {
        this.setState({
            carteTiree: this.choisirCarteAleatoire(categorie) // Ajouter un second paramètre pour la sous-catégorie si nécessaire
        }, callback); // Appeler le callback après la mise à jour de l'état
    }
    choisirCarteAleatoire(categorie, sousCategorie) {
        const cartes = this.state.cartes[categorie][sousCategorie]; // Accéder aux sous-catégories
        const carteAleatoire = cartes[Math.floor(Math.random() * cartes.length)];
        return <Carte key={carteAleatoire.id} image={carteAleatoire.image} categorie={categorie} />;
    }
    avancerPion = (nombre) => {
        const { categorieVisible, categorieSelectionnee } = this.state;

        // Si une catégorie est visible (3 ou 5), mais qu'aucune catégorie n'a été sélectionnée, empêcher de lancer le dé
        if ((categorieVisible === 3 || categorieVisible === 5) && !categorieSelectionnee) {
            this.setState({
                messageErreur: "Veuillez choisir une catégorie de carte avant de relancer le dé."
            });
            return;
        }
        // Réinitialiser les états des cartes supplémentaires à null chaque fois que le dé est lancé
        this.setState({
            categorieVisible: null,
            carteSupplementaire: null, // Réinitialiser l'image supplémentaire de la catégorie 4
            carteSupplementaireCategorie3: null, // Réinitialiser l'image supplémentaire de la catégorie 3
            categorieSelectionnee: false, // Réinitialiser la sélection de la catégorie
            messageErreur: null, // Réinitialiser le message d'erreur
        });

        let positionActuelle = this.state.positionPion;
        let positionsRestantes = nombre; // Nombre de cases restantes à avancer

        const deplacerPion = () => {
            if (positionsRestantes > 0) {
                positionActuelle = (positionActuelle % 6) + 1;
                this.setState({ positionPion: positionActuelle });
                positionsRestantes--;

                setTimeout(deplacerPion, 500); // Déplacer le pion toutes les 500 millisecondes
            } else {
                if (positionActuelle === 3 || positionActuelle === 5) {
                    this.setState({
                        afficherOptionsCategorie: true,
                        categorieVisible: positionActuelle
                    });
                } else {
                    this.setState({ categorieVisible: positionActuelle }, () => {
                        this.tirerCarte(positionActuelle, () => {
                            if (positionActuelle === 2) {
                                this.gererActionCaseDeux();
                            }
                        });
                    });
                }
            }
        };

        // Si le nombre est 6, cela signifie un tirage de 6, donc on déplace le pion de 6 cases
        if (nombre === 6) {
            positionsRestantes = 6;
        }

        // Lancer le déplacement du pion
        deplacerPion();
    }
    gererActionCaseDeux = () => {
        const carteTiree = this.state.carteTiree;
        if (!carteTiree) return;

        // Vérifier si l'image tirée est de la catégorie 2
        if (carteTiree.props.categorie === 2) {
            // Vérifier si l'image est categorie2/image1 ou categorie2/image2
            if (carteTiree.props.image.includes('categorie2/image1.png')) {
                // Tirer une carte de la catégorie 4 et l'afficher à côté
                const carteSupplementaire = this.choisirCarteAleatoire(4);
                this.setState({ carteSupplementaire });
            } else if (carteTiree.props.image.includes('categorie2/image2.png')) {
                // Choisir aléatoirement une sous-catégorie de la catégorie 3
                const sousCategories = ['vert', 'bleu'];
                const sousCategorieChoisie = sousCategories[Math.floor(Math.random() * sousCategories.length)];

                // Tirer une carte de la sous-catégorie choisie de la catégorie 3 et l'afficher à côté
                const carteSupplementaireCategorie3 = this.choisirCarteAleatoire(3, sousCategorieChoisie);
                this.setState({ carteSupplementaireCategorie3 });
            }
        }
    }

    handleCategorieSelect = (sousCategorieChoisie) => {
        const categorie = this.state.categorieVisible;
        this.setState({
            afficherOptionsCategorie: false,
            carteTiree: this.choisirCarteAleatoire(categorie, sousCategorieChoisie),
            categorieSelectionnee: true, // Confirmer qu'une catégorie a été sélectionnée
            messageErreur: null, // Effacer tout message d'erreur
        });
    };


    choisirCarteAleatoire(categorie, sousCategorie) {
        let cartes;
        if (categorie === 3 || categorie === 5) {
            cartes = this.state.cartes[categorie][sousCategorie];
        } else {
            cartes = this.state.cartes[categorie];
        }
        const carteAleatoire = cartes[Math.floor(Math.random() * cartes.length)];
        return <Carte key={carteAleatoire.id} image={carteAleatoire.image} categorie={categorie} />;
    }

    render() {
        return (
            <div className='main'>

                <div className='mainContent'>
                    <h1 className='mainTitle'>Jeu de Giguy</h1>
                    {/* <img src="./assets/images/categorie1/image1.jpeg" alt="123123" /> */}
                    <div className="plateau">
                        {[
                            { nom: "", image: "./assets/images/case/rejouer.png" },
                            { nom: "Giguy", image: "./assets/images/case/giguy.png" },
                            { nom: "Farfadet breton", image: "./assets/images/case/cornichon.jpeg" },
                            { nom: "Astuce/Oeil", image: "./assets/images/case/oeil.png" },
                            { nom: "Farfadet breton", image: "./assets/images/case/cornichon.jpeg" },
                            { nom: "Astuce/Oeil", image: "./assets/images/case/oeil.png" },
                        ].map((caseInfo, index) => (
                            <Case key={caseInfo.nom + index} numero={caseInfo.nom} image={caseInfo.image} />
                        ))}
                        <Pion position={this.state.positionPion} />
                    </div>
                    <div className='GamePlay'>
                        <De avancerPion={this.avancerPion} />
                    </div>
                </div>
                <div className='cardSelect'>
                        <Modal /> 
                    <h1 className='mainTitle'>Ce site Web est en cours d'écriture
                        MERCI DE NE PAS COMMUNIQUER SON URL</h1>
                    {this.state.messageErreur && (
                        <p className="erreur-message">{this.state.messageErreur}</p>
                    )}
                    {this.state.afficherOptionsCategorie && (
                        <SelectCategorie onSelect={this.handleCategorieSelect} />
                    )}
                    {this.state.carteTiree && !this.state.afficherOptionsCategorie && (
                        <div className={`tas-carte`}>
                            {this.state.carteTiree}
                        </div>
                    )}
                    {this.state.carteSupplementaire && (
                        <div className={`tas-carte supplementaire`}>
                            {this.state.carteSupplementaire}
                        </div>
                    )}
                    {this.state.carteSupplementaireCategorie3 && (
                        <div className={`tas-carte supplementaire-categorie3`}>
                            {this.state.carteSupplementaireCategorie3}
                        </div>
                    )}
                </div>

            </div>
        );
    }
}

export default Board;
