import React from 'react';

const Pion = ({ position }) => {
    const caseSize = 254;
    let x, y;

    if (position <= 3) {
        // Première ligne, de gauche à droite
        x = ((position - 1) % 3) * caseSize + (caseSize / 2);
        y = Math.floor((position - 1) / 3) * caseSize + (caseSize / 2);
    } else {
        // Deuxième ligne, de droite à gauche (inverser l'ordre)
        const newPosition = 9 - position + 1; // Inverser l'ordre des positions sur la deuxième ligne
        x = ((newPosition - 1) % 3) * caseSize + (caseSize / 2);
        y = Math.floor((newPosition - 1) / 3) * caseSize + (caseSize / 2);
    }

    return (
        <div className="pion" style={{ left: `${x}px`, top: `${y}px`, transform: 'translate(-170%, -115%)' }}></div>
    );
}

export default Pion;
