import React from 'react';

const Case = ({ numero, image }) => {
    return (
        <>
        <div className="case">{numero}
        <img src={image} alt={numero} style={{ width: '50%', height: 'auto' }} />
        </div>
        </>
    );
}

export default Case;
