import React from 'react';

import Board from "../Plateau/Board";

function App() {
    return (
        <>
            <Board />
        </>
    );
}

export default App;